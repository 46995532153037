//font style and family
$fontFamily: "Poppins";
$SourceSansPro: 'Source Sans Pro', Roboto, Arial, sans-serif;
//colors
$regular-grey: #3d3d3d;
$white: #ffffff;
$green: #25c2a3;
$orange: #EA4335;
$red: #ff0000;
$text-orange: #ee7b6a;
$light-grey: #f2f2f2;
$light-grey2: #f9f7fa;
$nav-dim-white: #f1d3ca;
$dark-grey: #7f7f7f;
$title-grey:#6c6c6c;
$border-grey: #cccccc;
$blue:#3f51b5;
$light-grey3:#808080;
$title-grey: #8C8C8C;
