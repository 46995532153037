@import "./variables.scss";
// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// // Plus imports for other components in your app.
// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();
// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $assesment-primary: mat-palette($mat-indigo);
// $assesment-accent: mat-palette($mat-pink, A200, A100, A400);
// // The warn palette is optional (defaults to red).
// $assesment-warn: mat-palette($mat-red);
// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $assesment-theme: mat-light-theme((
//   color: (
//     primary: $assesment-primary,
//     accent: $assesment-accent,
//     warn: $assesment-warn,
//   )
// ));
// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($assesment-theme);
// /* You can add global styles to this file, and also import other style files */
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* You can add global styles to this file, and also import other style files */

// body {
//     font-family: "Arial-BoldMT", "Arial Bold", "Arial", sans-serif;
// }
// html,
// body {
//     height: 100%;
// }
body {
  font-family: $SourceSansPro;
  font-weight: 400;
  font-size: 16px;
  color: $dark-grey;
  margin: 0;
  background-color: $light-grey;
}

.main-content-wrapper {
  // width: 1440px;
  // margin: 0 auto;
  margin: 25px 25px;
}
.content-wrapper {
  background: $white;
  padding: 15px 15px 15px;
}

.component-container {
  padding: 15px;
  border: 1px solid #ccc;
}

hr {
  border-color: #ccc;
}

::ng-deep .mat-tab-body-content {
  overflow: hidden !important;
}

::ng-deep .mat-tab-labels {
  float: right;
}
::ng-deep .mat-paginator-container {
  font-family: $SourceSansPro;
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border: 0px;
    }
  }
}
.mat-form-field-flex {
  border: 0px;
}
::ng-deep .mat-tab-label .mat-tab-label-content {
  color: $text-orange;
  font-family: $SourceSansPro;
}

::ng-deep .mat-button-wrapper {
  span {
    padding: 3px;
  }
}

::ng-deep .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: $text-orange;
}

::ng-deep .mat-table,
.mat-typography {
  font: 16px/22px $SourceSansPro;
}

::ng-deep .mat-flat-button,
.mat-raised-button {
  height: 48px;
  font-size: 16px;
  padding: 2px 16px 5px;
  border: 1px solid $border-grey;
  font-family: $SourceSansPro;
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 12%);
}
.no-underline {
  text-decoration: none !important;
}
.error {
  color: $red;
}
.errorMsg {
  color: $red;
  text-align: right;
  padding: 15px 0;
}

::ng-deep .mat-flat-button {
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 12%);
}

::ng-deep .mat-tab-group,
.mat-expansion-panel-header {
  font-family: $SourceSansPro;
}

::ng-deep .mat-form-field {
  border: 0px;
}

::ng-deep .mat-cell {
  font-size: 18px;
  vertical-align: top;
}

::ng-deep .mat-tab-body.mat-tab-body-active {
  overflow-y: hidden;
}

::ng-deep .mat-tab-body-wrapper {
  background-color: $white;
}

/* login page */
.btn-login {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 5px 16px;
  outline: 0;
  border-width: 0px;
  border-radius: 4px;
}
/**/

table {
  width: 100%;
}

.table-container {
  margin-top: 15px;
  .mat-elevation-z8 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    .tag-type {
      color: $regular-grey;
      cursor: pointer;
    }
    a {
      color: $regular-grey;
      cursor: pointer;
      font-weight: 600;
    }
    .publish,
    .unpublish {
      font-size: 30px;
      color: $green;
    }
  }
  .mat-sort-header-content{
      font-size: 18px;
  }
  ::ng-deep .mat-header-cell {
    background-color: $light-grey;
    font-size: 16px;
    color: $dark-grey;
    font-weight: 600;
    padding: 10px 20px;
    &:first-child {
      width: 35%;
    }
    &:last-child {
      width: 15%;
    }
  }
  .mat-cell {
    padding: 15px 20px;
    span.tag-desc {
      font-size: 16px;
    }
  }
  
  .tag-type {
    color: #337ab7;
    text-decoration: none;
  }
}
::ng-deep tr.mat-row:hover {
  background-color: $light-grey;
  cursor: pointer;
}
.mat-tab-body.mat-tab-body-active {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden !important;
  z-index: 1;
  flex-grow: 1;
}

.mat-mini-fab {
  background-color: $green;
  margin: 5px;
  .mat-button-wrapper {
    padding: 0px 0px;
    .material-icons {
      font-size: 20px;
    }
  }
}
::ng-deep .mat-expansion-panel-header {
  background-color: $light-grey;
}
.btn-container {
  text-align: center;
  padding: 15px;
}
// .mat-paginator {
//     background-color: $light-grey3;
//     width: 100%;
//     .card-filter {
//         padding: 2%;
//         font-size: 15px;
//         .next-filter {
//             float: right;
//         }
//     }
// }
.title {
  background: $light-grey;
  padding: 15px;
  label {
    color: $white;
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
  }
}
.header-section {
  background: $blue;
  padding: 15px;
  .title {
    label {
      color: $white;
      font-size: 20px;
      font-weight: 500;
      padding: 10px;
    }
  }
}

.form-control {
  height: 48px;
}

.filter-section {
  margin: 15px 0px 15px;
  label {
    padding-left: 10px;
  }
  .input-fields-wrapper {
    display: flex;
      .input-wrapper{
          width: 100%;
      }
  }
}

.input-wrapper {
  display: flex;
  margin: 0 0 20px;
  input {
    margin-right: 10px;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
}

// ::ng-deep .mat-tab-group{
//     width: 100%;
//   }
.btn-bg-color {
  background-color: $green;
  color: $white;
  font-weight: 600;
  border: 1px solid $green;
}
.btn-green-text {
  color: $green;
  font-weight: 600;
  border: 0px solid $green;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 12%);
}
.btn-orange {
  background-color: $orange;
  color: $white;
  font-weight: 600;
  border: 1px solid $orange;
}
::ng-deep .mat-button-disabled.btn-bg-color {
  border: 0px;
}

.form-inputs {
  font-family: $SourceSansPro;
  padding: 15px 0;
  width: 100%;
  overflow: auto;
}

.input-class {
  margin: 0px 0px 35px 0px;
  label {
    display: flex; // margin-left:200px;
  }
  input {
    display: flex;
    padding: 6px;
    font-size: 14px;
    width: 100%;
    margin: auto;
  }
}
.cursor{
  cursor: pointer;
}

.red-asterisk{
  color: red;
}

/* notification */
snack-bar-container.success {
  background-color: #5cb85c;
  color : #fff;
  margin-top: 180px;
}

snack-bar-container.warn {
  background-color: #e61212;
  color : #fff;
  margin-top: 200px;
}

snack-bar-container.notification simple-snack-bar{
  font-size: 18px !important;
  font-weight: bold;
}